.st-page {
    width: 900px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    align-items: center;
}

.st-chart-container { 
    margin-top: 24px;
    padding: 8px;
    background-color: var(--neutral-7);
    width: fit-content;
    border-radius: var(--border-radius);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex; 
    justify-content: center;
    align-items: center;
}

.st-canvas {
    background-color: var(--neutral-6);
    width: 800px;
    height: 400px;
    padding: 8px;
    border-radius: var(--border-radius);
    box-shadow: inset 0 0 10px hsla(0, 0%, 0%, 0.15);
}

.st-sunburst-plot { 
    display: flex; 
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.st-sunburst-plot-title {
    margin-bottom: 8px;
    font-weight: 400;
}


.st-sunburst-wrap {
    width: 800px;
    height: 450px;
    display: flex;
    justify-content: space-around; 
    align-items: center;
    background-color: var(--neutral-6);
    box-shadow: inset 0 0 10px hsla(0, 0%, 0%, 0.15);
}

.st-sunburst-date-input {
    width: 12ch;
    text-align: center;
}

.date-picker-container {
    margin-top: 32px;
    background-color: var(--neutral-7);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.date-picker-container .MuiInputBase-root {
    color: var(--primary-1);
}

.date-picker-container input:focus { 
    background-color: unset;
}

.timer-page { 
    width: fit-content;
    display: flex; 
    flex-direction: column;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
}

.timer-remaining-text {
    animation: blinker 0.8s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 50%;
  }
}

.timer-container {
    --content-width: 170px;
    height: 270px;
    margin: 16px 0px;
    display: flex; 
    flex-direction: column; 
    align-items: center;
    justify-content: space-between;
    background-color: var(--neutral-7);
    border-radius: var(--border-radius);
    padding: 16px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.timer-container-duration {
    display: flex;
    justify-content: space-between;
    width: var(--content-width);
}

.timer-h1 { 
    display: inline-block;
}

.timer-input-time-component { 
    width: 50px;
    text-align: center;
    font-size: 1.2em;
    background-color: var(--neutral-6)
}
.timer-input-time-component:focus { 
    background-color: var(--neutral-5);
}

.timer-text-colon { 
    font-size: 1.2em;
    display: inline-flex;
    align-items: center;
}

.timer-buttons-tag-container {
    display: flex;
    flex-direction: column;
}

.timer-button-container { 
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
}

.timer-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; 
    top: -1px;

    width: 55px;
    height: 55px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 1px 3px 0px;
    border-width: 1px;
    border-radius: 50%;

    font-family: "Roboto";
    font-weight: semi-bold;
    font-size: 14px;
    color: var(--neutral-7);
    letter-spacing: 0.04em;
}
.timer-button:active{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 0px;
    top: 0px;
}

.timer-button-start {
    color: var(--accent-green-1);
    background-color: var(--accent-green-5);
    border-color: var(--accent-green-1);
}
.timer-button-start:hover {
    background-color: var(--accent-green-6);
}
.timer-button-start:active {
    background-color: var(--accent-green-5);
}

.timer-button-stop {
    color: var(--accent-red-1);
    background-color: var(--accent-red-5);
    border-color: var(--accent-red-1);
}
.timer-button-stop:hover {
    background-color: var(--accent-red-6);
}
.timer-button-stop:active {
    background-color: var(--accent-red-5);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 0px;
    top: 0px;
}

.timer-input-tag {
    width: var(--content-width);
    font-size: 16px;
    height: 32px;
    background-color: var(--neutral-6);
}
.timer-input-tag:focus {
    background-color: var(--neutral-5);
}

.timer-tag-container {
    margin-top: 16px;
}

.timer-icon.MuiSvgIcon-root {
    width: 28px; 
    height: 28px;
}

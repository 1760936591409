
.dr-body-record-row:hover td:not([rowspan]),
.dr-body-record-row:hover td[rowspan="1"] {
    background-color: var(--primary-7)
}

.dr-body-record-row-selected td:not([rowspan]){
    background-color: var(--primary-6-8)
}
.dr-body-record-row-selected:hover td:not([rowspan]){
    background-color: var(--primary-6-9)
}

.stopwatch-page {
    width: fit-content;
    display: flex; 
    flex-direction: column;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
}

.dr-table-container {
    background-color: var(--neutral-7);
    border-radius: var(--border-radius);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-top: 24px;
    margin-bottom: 16px;
}

.dr-table-container table thead tr th {
    border-right: 1px solid rgba(224, 224, 224, 1);
}
.dr-table-container table tbody tr td {
    border-right: 1px solid rgba(224, 224, 224, 1);
}

.dr-table-container 
table tbody tr td 
.MuiFormControl-root
.MuiInputBase-root {
    color: var(--primary-1);
    font-size: 14px;
    height: 40px;
}

.dr-table-container 
table tbody tr td 
.tag-container
.MuiAutocomplete-root
.MuiFormControl-root
.MuiInputBase-root {
    height: 40px;
}

.dr-table-container 
table tbody tr td 
.tag-container
.MuiAutocomplete-root
.MuiFormControl-root
.MuiInputBase-root 
input {
    font-size: 14px;
    color: var(--primary-1);
}

.dr-table-container .MuiTableCell-root {
    color: var(--primary-1);
}

.dr-table-time-column {
    min-width: 140px;
    max-width: 140px;
}

.dr-table-tag-column {
    min-width: 210px;
}

.dr-table-button {
    width: 40px;
    height: 40px;
    border-width: 1px; 
    border-color: var(--neutral-3); 
    font-weight: 550;
    font-size: 14px;
    color: var(--primary-2); 
    background-color: var(--neutral-7);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 1px 3px 0px;
}
.dr-table-button:hover { 
    background-color: var(--primary-6-5);
}
.dr-table-button:active { 
    background-color: var(--neutral-7);
}

.dr-table-button-accent-red {
    color: var(--accent-red-3);
}
.dr-table-button-accent-red:hover {
    background-color: var(--accent-red-6-5);
}
.dr-table-button-accent-red:active {
    background-color: var(--neutral-7);
}

.dr-table-button-accent-green {
    color: var(--accent-green-3);
}
.dr-table-button-accent-green:hover {
    background-color: var(--accent-green-6-5);
}
.dr-table-button-accent-green:active {
    background-color: var(--neutral-7);
}

.dr-cell-editing {
    background-color: var(--neutral-7);
}
.dr-cell-editing:focus {
    background-color: var(--neutral-6);
}

.dr-cell-time-editing {
    width: 9ch;
}

.stopwatch-header { 
    margin-top: 16px;
}

.daily-record-time-input {
    width: 10ch;
    text-align: center;
}

.stopwatch-container { 
    --content-width: 170px;
    margin-top: 16px;
    margin-bottom: 16px;
    height: 220px;
    display: flex; 
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    background-color: var(--neutral-7);
    padding: 16px;
    border-radius: var(--border-radius);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.stopwatch-button-container { 
    display: flex; 
    justify-content: space-between;
    margin-bottom: 8px;
}

.stopwatch-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; 
    top: -1px;

    width: 55px;
    height: 55px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 1px 3px 0px;
    border-width: 1px;
    border-radius: 50%;

    font-family: "Roboto";
    font-weight: semi-bold;
    font-size: 14px;
    color: var(--neutral-7);
    letter-spacing: 0.04em;
}
.stopwatch-button:active{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 0px;
    top: 0px;
}

.stopwatch-button-start {
    background-color: var(--accent-green-5);
    color: var(--accent-green-1);
    border-color: var(--accent-green-1);
}
.stopwatch-button-start:hover {
    background-color: var(--accent-green-6);
}
.stopwatch-button-start:active {
    background-color: var(--accent-green-5);
}

.stopwatch-button-stop {
    color: var(--accent-red-1);
    background-color: var(--accent-red-5);
    border-color: var(--accent-red-1);
}
.stopwatch-button-stop:hover {
    background-color: var(--accent-red-6);
}
.stopwatch-button-stop:active {
    background-color: var(--accent-red-5);
}

.stopwatch-tag-container {
    margin-top: 16px;
}

.stopwatch-icon.MuiSvgIcon-root {
    width: 28px; 
    height: 28px;
}

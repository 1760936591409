.tag-input { 
    width: 170px;
}

.tag-input input {
    box-shadow: none;
    overflow-x: scroll;
    text-overflow: clip !important;
}

.tag-no-labels { 
    display: flex; 
    justify-content: center;
    align-items: center;
    color: var(--neutral-3);
}
.tag-no-labels:hover { 
    background-color: inherit;
}

.tag-option { 
    width: 100%;
    padding-right: 8px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}

.tag-container .MuiAutocomplete-popper {
    min-width: 200px;
    width: fit-content !important;
} 


.tag-container 
.MuiAutocomplete-popper 
.MuiPaper-root
.MuiAutocomplete-listbox::-webkit-scrollbar {
    width: 5px;
}

.tag-container 
.MuiAutocomplete-popper 
.MuiPaper-root
.MuiAutocomplete-listbox::-webkit-scrollbar-track {
    background-color: transparent;
    padding: 2px;
}

.tag-container 
.MuiAutocomplete-popper 
.MuiPaper-root
.MuiAutocomplete-listbox::-webkit-scrollbar-thumb {
    margin: 4px 0;
    background-color: var(--neutral-3);
    border-radius: 3px;
    outline: 1px solid var(--neutral-5);
}

.footer {
    display: block;
    margin: 24px 0;
    color: var(--neutral-2);
    text-align: center;
    text-decoration: none;
}
.footer:hover {
    color: var(--neutral-3);
}
